import axios from 'axios'

const API = '/carteleras'

const Carteleras = {
    listar(params){
      return axios(`${API}`,{params})
    },
    listarPendientes(params){
      return axios(`${API}/pendientes`,{params})
    },
    listarPropias(params){
      return axios(`${API}/propias`,{params})
    },
    mostrar(id){
      return axios(`${API}/${id}`)
    },
    aprobar(payload){
      return axios.put(`${API}/aprobar`, payload)
    },
    rechazar(params){
      return axios.put(`${API}/rechazar`,params)
    },
    finalizarAnuncio(params){
      return axios.put(`${API}/finalizar`,params)
    },
    interesados(id){
      return axios(`${API}/interesados/${id}`)
    },
    subirImagen(payload){
      return axios.post(`${API}/imagenes`,payload)
    },
    eliminarImagen(params){
      return axios.delete(`${API}/imagenes/eliminar`,params)
    },
    subirImagenesWeb(params){
      return axios.post(`${API}/imagenes-web`,params)
    },  
    listarImagenes(id_cartelera){
      return axios.get(`${API}/imagenes/${id_cartelera}`)
    },  
    guardar(payload){
      return axios.post(`${API}`, payload)
    },  
    eliminar(params){
      return axios.delete(`${API}/eliminar`,params)
    },
    listarPorVivienda(id_vivienda, params){
      return axios(`${API}/viviendas/${id_vivienda}`, {params})
    },  
    
}

export default Carteleras
