import axios from 'axios'

const API = '/categorias'

const Categorias = {
    listar(params){
      return axios(`${API}`,{params})
    },
    guardar(payload){
      return axios.post(`${API}`,payload)
    },
    obtenerTipo(id){
      return axios(`${API}/mostrar/${id}`)
    },
    eliminar(id){
      return axios.delete(`${API}/${id}`)
    },
}

export default Categorias
