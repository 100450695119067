import { render, staticRenderFns } from "./crearAnuncio.vue?vue&type=template&id=1b2b87bf&scoped=true"
import script from "./crearAnuncio.vue?vue&type=script&lang=js"
export * from "./crearAnuncio.vue?vue&type=script&lang=js"
import style0 from "./crearAnuncio.vue?vue&type=style&index=0&id=1b2b87bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b2b87bf",
  null
  
)

export default component.exports