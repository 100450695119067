<template>
    <section class="anuncios-crear mt-4 ml-4" v-loading="loading">
        <ValidationObserver tag="div" ref="form">
            <div class="row">
                <div class="col-12">
                    <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                        <el-breadcrumb-item v-if="$route.params.id_cartelera" :to="{ name: 'anuncios.solicitudes.ver',params:{id_cartelera: $route.params.id_cartelera} }">Anuncio {{ cartelera.titulo }}</el-breadcrumb-item>
                        <el-breadcrumb-item v-else :to="{ name: 'anuncios.mis.anuncios' }">Mis anuncios</el-breadcrumb-item>
                        <el-breadcrumb-item>{{ $route.params.id_cartelera ? 'Editar anuncio' : 'Nuevo anuncio'}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <p class="f-20 f-500 col-12 mb-3">{{ $route.params.id_cartelera ? 'Editar anuncio' : 'Nuevo anuncio'}}</p>
                         <ValidationProvider tag="div" class="col-12 col-sm-6 mb-3" v-slot="{errors}" rules="required|max:100" name="título">
                            <p class="f-12 pl-2 label-inputs">Título</p>
                            <el-input placeholder="Título del anuncio" v-model="cartelera.titulo" show-word-limit maxlength="100" size="small" class="w-100"></el-input>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-6 mb-3 mx-0 p-0">
                            <div class="row mx-0 ml-3">
                                <div class="wh-40px rounded-circle d-middle-center my-auto" :style="`background-color:${categoriaSelected.color ? categoriaSelected.color : '#d7d7d7'}`">
                                    <i :class="`text-center icon ${categoriaSelected.icono ? categoriaSelected.icono : 'icon-account-outline'} op-05 f-20`" />
                                </div>
                                <div class="col">
                                <p class="f-12 label-inputs">Tipo anuncio</p>
                                    <ValidationProvider tag="div" v-slot="{errors}" rules="required" name="categoría">
                                        <el-select v-model="cartelera.id_categoria" placeholder="Seleccionar tipo" size="small" class="w-100" @change="setCategoriaSelected">
                                            <el-option v-for="(categoria,i) in categorias" :key="i" :label="categoria.nombre" :value="categoria.id" />
                                        </el-select>
                                      <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div v-if="categoriaSelected.dinero" class="col-12 col-sm-6 col-lg-6 mb-3">
                            <ValidationProvider tag="div" v-slot="{errors}" rules="required|numeric|min_value:1|max_value:16777215" name="valor">
                                <p class="f-12 pl-2 label-inputs">Valor</p>
                                <el-input-number type="number" placeholder="Escribe el valor del elemento" v-model="cartelera.dinero" :controls="false" class="w-100 text-left" size="small"/>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-6 mb-3">
                            <div class="row">
                                <div class="col-12">
                                    <ValidationProvider tag="div" v-slot="{errors}" rules="required" name="fecha">
                                        <p class="f-12 pl-2 label-inputs">Fecha de inicio</p>
                                        <el-date-picker v-model="cartelera.vigencia_inicio" type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" format="dd [de] MMMM yyyy" placeholder="Seleccionar la fecha" size="small" class="w-100" />
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 mt-2">
                                    <ValidationProvider tag="div" v-slot="{errors}" rules="required|numeric|min_value:1|max_value:100" name="duración">
                                        <p class="f-12 pl-2 label-inputs">Duración (días)</p>
                                        <el-input placeholder="Número de días" v-model="duracion"  size="small"/>
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-lg-6 mb-3">
                            <p class="f-12 pl-2 label-inputs">Descripción</p>
                            <vue-editor :editor-toolbar="customToolbar" :class="{'border border-danger': cartelera.descripcion.length > 750}" v-model="cartelera.descripcion"/>
                            <p class="f-10 text-right" :class="{'text-danger': cartelera.descripcion.length > 750}">{{ cartelera.descripcion.length }} / 750</p>
                        </div> -->
                        <div class="col-12 col-sm-6 col-lg-6 mb-3">
                            <ValidationProvider tag="div" v-slot="{errors}" rules="required|max:750" name="descripción">
                                <p class="f-12 pl-2 label-inputs">Descripción</p>
                                <el-input placeholder="Descripción del anuncio" v-model="cartelera.descripcion" type="textarea" show-word-limit maxlength="750" :autosize="{ minRows: 4, maxRows: 4}" />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3">
                        <p class="f-12 pl-2 label-inputs">Enlace</p>
                        <el-input placeholder="Enlace del anuncio" v-model="cartelera.enlace" show-word-limit maxlength="500" size="small"/>
                      </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-auto d-flex">
                            <i :class="`icon-pin mr-2 cr-pointer f-17 ${cartelera.top == 1 ? 'text-blue' : 'text-general'}`" @click="cartelera.top = (cartelera.top == 1) ? 0 : 1" />
                            <p class="f-14">Fijar el anuncio</p>
                        </div>
                        <div class="col-auto d-flex el-checkbox-negro">
                            <el-checkbox v-model="cartelera.destacado" :true-label="1" :false-label="0">Destacar el anuncio</el-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-auto">
                            <button class="btn btn-general h-32px f-14 f-300 px-4" @click="guardar">Publicar</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="row mb-3">
                        <div class="col-auto">
                            <p class="f-20 f-500 col-12">Imágenes del anuncio</p>
                        </div>
                    </div>
                    <div class="row">
                      <el-upload
                       v-if="!$route.params.id_cartelera"
                       action="#"
                      :file-list="imagenes"
                      :on-change="handleChange"
                      list-type="picture-card"
                      :auto-upload="false">
                         <i slot="default" class="el-icon-plus"></i>
                         <div slot="file" slot-scope="{file}">
                           <img
                             class="el-upload-list__item-thumbnail"
                             :src="file.url" alt=""
                           >
                           <span class="el-upload-list__item-actions">
                             <span
                            :class="`${file.url == favorito.url || imagenes[0].url == file.url ? 'icon-me_interesa' : 'icon-star-outline'}`"
                            @click="setImagenPortada(file)"
                            >
                            </span>
                             <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                            >
                            <i class="el-icon-zoom-in"></i>
                            </span>
                             <span
                               class="el-upload-list__item-delete"
                               @click="handleRemove(file)"
                             >
                               <i class="el-icon-delete"></i>
                             </span>
                           </span>
                         </div>
                      </el-upload>
                      <el-upload
                        v-else
                        action="#"
                        list-type="picture-card"
                        :file-list="cartelera.imagenes"
                        :on-change="handleChangeImmediate"
                        :multiple="false"
                        :auto-upload="false">
                          <i slot="default" class="el-icon-plus" />
                          <div slot="file" slot-scope="{file}">
                            <img
                              class="el-upload-list__item-thumbnail"
                              :src="file.imagen" alt=""
                            >
                            <span class="el-upload-list__item-actions">
                              <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                              >
                                <i class="el-icon-zoom-in"></i>
                              </span>
                              <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="eliminarImagenRemota(file)"
                              >
                                <i class="el-icon-delete"></i>
                              </span>
                            </span>
                          </div>
                      </el-upload>

                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>   
                  </div>
                </div>
            </div>
        </ValidationObserver>
        <!-- Partials -->
        <modal-ver-img ref="modalabrirVerImg" />
    </section>
</template>
<script>
import Carteleras from '~/services/carteleras'
import Categorias from '~/services/carteleras/categorias'
import moment from 'moment'
import { image } from 'vee-validate/dist/rules'
import { VueEditor } from "vue2-editor";

export default {
    components:{
        VueEditor,
        modalVerImg: () => import('./partials/modalVerImg'),
    },
    data(){
        return{
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < moment().subtract(1,'d');
              },
            },
            favorito: {},
            categoriaSelected: {},
            categorias:[],
            categoria: null,
            index: null,
            loading: false,
            duracion : 1,
            cartelera:{
              id: null,
              id_categoria : null,
              titulo: '',
              imagenes: [],
              dinero: 0,
              descripcion: '',
              vigencia_inicio: null,
              vigencia_fin: null,
              top: 0,
              destacado: 0,
              enlace: ''
            },
            imagenes: [],
            imageUrl: '',
            disabled: false,
            dialogImageUrl: '',
            dialogVisible: false,
            // before ->
            
            fijarAnuncioCheck: false,
            customToolbar: [
              ['link']
            ],
        }
    },
    async created(){
      this.listarCategorias()
      if(typeof this.$route.params.id_cartelera != 'undefined'){
        await this.mostrar()
        this.cartelera.imagenes.map(el=>{ el.url = el.imagen; return el.url} )
        this.imagenes = this.cartelera.imagenes
      }
    },
    methods:{
        fijarAnuncio(){
            this.fijarAnuncioCheck = !this.fijarAnuncioCheck
        },
        verImagen(){
            this.$refs.modalabrirVerImg.toggle()
        },
        setImagenPortada(file){
          // se busca el obj en el array para ponerlo de primero
          let index = this.imagenes.indexOf(file)
          this.imagenes.splice(index,1)
          this.imagenes.unshift(file)
          this.favorito = file
        },
        setCategoriaSelected(idCategoria){
          let obj = this.categorias.find(el=>el.id==idCategoria)
          this.categoriaSelected = obj
          this.cartelera.dinero = 0
        },
        async listarCategorias(){
          try {
            this.loading = true
            let params={
              caso: 1
            }
            const {data} = await Categorias.listar(params)
            this.categorias = data.data
            this.categoria = data.data.id_categoria
            
            this.loading = false
          } catch (e){
              this.errorCatch(e)
              this.loading = false
          }          
        },
        // metodos el-upload para editar
        handleChangeImmediate(file, ){          
          this.subirImagen(file)
        },
        async subirImagen(file){
          try {
            this.loading = true
            let formData = new FormData();
            formData.append('imagen', file.raw);
            formData.append('id_cartelera', this.cartelera.id);
            const {data} = await Carteleras.subirImagen(formData)
            this.loading = false
            if(data.success){
              this.mostrar()
            }
          } catch (e){
            this.loading = false
            this.errorCatch(e)
          }
        },
        async eliminarImagenRemota(file){
          try {
            console.log(file);
            this.loading = true  
            let params = {
              id_cartelera_imagenes : file.id
            }          
            const {data} = await Carteleras.eliminarImagen({params})
            this.loading = false
            if(data.success){
              this.mostrar()
            }
          } catch (e){
            this.loading = false
            this.errorCatch(e)
          }
        },
        // --------------------------
        // metodos el-upload para el crear
        handlePictureCardPreview(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
        },
        handleChange(file,fileList){
          this.imagenes = fileList
        },
        handleRemove(file, fileList) {
          let index = this.imagenes.indexOf(file)
          this.imagenes.splice(index,1)
        },
        // ----------------------------
        async guardar(){
          try {

            let validate = await this.$refs.form.validate()

            if(!validate || this.cartelera.descripcion.length > 750) return this.notificacion('','Campos incompletos','warning')

            this.cartelera.vigencia_fin = moment(this.cartelera.vigencia_inicio).add(this.duracion, 'days').format('YYYY-MM-DD')
            this.cartelera.vigencia_inicio = moment(this.cartelera.vigencia_inicio).format('YYYY-MM-DD')

            this.loading = true
            let payload= {
              id_cartelera : this.cartelera.id,
              titulo : this.cartelera.titulo,
              descripcion : this.cartelera.descripcion,
              mostrar_datos: 1,
              vigencia_inicio: this.cartelera.vigencia_inicio,
              vigencia_fin: this.cartelera.vigencia_fin,
              dinero: this.cartelera.dinero,
              id_categoria: this.cartelera.id_categoria,
              top: this.cartelera.top,
              destacado: (this.cartelera.destacado) ? 1 : 0,
              webNotificacion: 1,
              enlace: this.cartelera.enlace
            }
            
            const {data} = await Carteleras.guardar(payload)

            if(this.imagenes.length && !this.cartelera.id){

              let imagenes = {
              id_cartelera: data.data.id,
              imagenes: this.imagenes.map((el)=>{return el.raw})
              }
              const { dataI } = await Carteleras.subirImagenesWeb(this.crearFormData(imagenes))

            }
            
            this.loading = false
            if(data.success){
              // this.cartelera.imagenes = data.data              
              // this.zona.imagenes.forEach(imagen => {
              //   imagen.url = imagen.imagen
              // });
              // this.imagenes = this.cartelera.imagenes
              this.$notify({
                title: 'Guardar anuncio',
                message: data.mensaje,
                type: 'success'
              });
              this.$router.push({ name:"anuncios.solicitudes.ver", params:{id_cartelera:data.data.id} })
            }
          } catch (e){
            this.loading = false
            this.$notify({
              title: 'Guardar cartelera',
              message: 'Error guardando',
              type: 'warning'
            });
            this.errorCatch(e)
          }
        },
        async mostrar(){
          try {
            this.loading = true
            
            const {data} = await Carteleras.mostrar(this.$route.params.id_cartelera)
            this.cartelera = data.data
            // duracion
            let inicio =  moment(this.cartelera.vigencia_inicio)
            let fin = moment(this.cartelera.vigencia_fin)
            this.duracion = fin.diff(inicio,'days')
            // icono de la categoria
            this.categoriaSelected = this.categorias.find(el=>el.id == this.cartelera.id_categoria)

            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }  
        },
        
        
    }
}
</script>
<style lang="scss" scoped>
.anuncios-crear{
    .container-img{
        width: 120px;
        height: 120px;
        position: relative;
        .portada{
            background: #93EDCF;
            width: 54px;
            height: 20px;
            position: absolute;
            top: -10px;
            left: 33px;
            border: 1px solid white;
        }
        i.icon-me_interesa{
            position: absolute;
            right: 3px;
            top: 3px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        .opciones{
            position: absolute;
            bottom: 7px;
            left: 36px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        &:hover{
            .opciones, .icon-me_interesa{
                transition: .45s;
                opacity: 1;
            }
        }
    }
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>